import React from "react";
import { Helmet } from "react-helmet";
import * as styles from "./style.module.scss";
import { StaticImage } from "gatsby-plugin-image";

import { ButtonUI, NoGapUI, TypographyUI } from "@ui";
import { LAPTOP_MOBILE_POINTER } from "@tools/constants";
import { BackgroundPosterCt, ButtonOfScrollCt, Layout } from "@components";
import sAdImg from "@images/selfworkers/service-ad.png";
import sDocImg from "@images/selfworkers/service-doc.png";
import sFnsImg from "@images/selfworkers/service-fns.png";
import sGeoImg from "@images/selfworkers/service-geo.png";
import sSuccessImg from "@images/selfworkers/service-success.png";
import sWalletImg from "@images/selfworkers/service-wallet.png";
import srcOrderStepsImg from "@images/selfworkers/order-steps.svg";
import srcOrderStep1Img from "@images/selfworkers/order-num-1.svg";
import srcOrderStep2Img from "@images/selfworkers/order-num-2.svg";
import srcOrderStep3Img from "@images/selfworkers/order-num-3.svg";
import bannerArrowImg from "@images/selfworkers/banner-arrow.svg";
import { Link } from "gatsby";
import styled from "styled-components";
import { useSendEvent } from "@tools/hooks";

const SelfworkersPage = () => {
  const { clickFindJob } = useSendEvent();
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>
          Работа для самозанятых | Вакансии для самозанятых удаленные | Самозанятые сервисы подработки
        </title>
        <meta
          name="description"
          content="Работа для самозанятых граждан. Поиск заказов для самозанятых. Место, где можно найти работу самозанятому. Быстро и безопасно!"
        />
        <link
          rel="canonical"
          href="https://xn--80aapgyievp4gwb.xn--p1ai/selfworkers"
        />
      </Helmet>
      <Layout>
        <div className={styles.containerTop}>
          <BackgroundPosterCt />
          <div className={styles.text}>
            <TypographyUI.H1
              swichPoint={LAPTOP_MOBILE_POINTER}
              className={styles.title}
              variant="h1"
            >Помогаем самозанятым <NoGapUI>вести бизнес</NoGapUI>
            </TypographyUI.H1>

            <TypographyUI.Body1Regular
              swichPoint={LAPTOP_MOBILE_POINTER}
              className={styles.description}
            >
              Сам.Маркет — платформа, где самозанятый из любой отрасли найдет
              подходящий заказ. Скачайте приложение, заполните профиль и
              приступайте к работе
            </TypographyUI.Body1Regular>

            <a
              onClick={clickFindJob}
              className={styles.bannerDownloadLink}
              rel="noreferrer"
              target="_blank"
              href="https://market.selfwork.ru"
            >
              <ButtonUI
                className={styles.tryButton}
                onClick={() => {}}
                $w="240px"
              >
                Попробовать
              </ButtonUI>
            </a>
          </div>

          <div className={styles.background}>
            <StaticImage
              className={styles.backgroundImg}
              src="../../images/selfworkers/background.png"
              loading="eager"
              placeholder="none"
              formats={["auto", "webp"]}
              width={536}
              height={620}
              layout="fixed"
              objectFit="contain"
              alt="background-img"
            />
          </div>
        </div>

        <ButtonOfScrollCt id="serviceFor" $w="100%" $mt="120px" />

        <div id="serviceFor" className={styles.containerServiceFor}>
          <TypographyUI.H2
            swichPoint={LAPTOP_MOBILE_POINTER}
            className={styles.title}
          >
            Для кого мы создали сервис
          </TypographyUI.H2>

          <TypographyUI.Body1Regular
            swichPoint={LAPTOP_MOBILE_POINTER}
            className={styles.description}
          >
            Сам.Маркет — уникальный продукт для самозанятых, которые еще не
            успели настроить бизнес-процессы. С помощью сервиса вы составите
            договор без ошибок, найдете клиентов и полностью автоматизируете
            получение дохода
          </TypographyUI.Body1Regular>
        </div>

        <div className={styles.containerServices}>
          <div className={styles.serviceItem}>
            <img
              height={56}
              width={56}
              className={styles.serviceItemImg}
              alt="service-ad"
              src={sAdImg}
            />
            <TypographyUI.H4Bold className={styles.serviceItemTitle}>
              Бесплатная реклама услуг
            </TypographyUI.H4Bold>
            <TypographyUI.Body1Regular
              className={styles.serviceItemDescription}
            >
              Выполняйте работу добросовестно, чтобы подняться в рейтинге
              самозанятых — чем лучше рейтинг, тем выше профиль в списке
            </TypographyUI.Body1Regular>
          </div>
          <div className={styles.serviceItem}>
            <img
              height={56}
              width={56}
              className={styles.serviceItemImg}
              alt="service-success"
              src={sSuccessImg}
            />
            <TypographyUI.H4Bold className={styles.serviceItemTitle}>
              Разнообразные проекты
            </TypographyUI.H4Bold>
            <TypographyUI.Body1Regular
              className={styles.serviceItemDescription}
            >
              Отправьтесь на прогулку с собакой, почините кран или придумайте
              текст посадочной страницы — в маркетплейсе вы найдете работу по
              душе
            </TypographyUI.Body1Regular>
          </div>
          <div className={styles.serviceItem}>
            <img
              height={56}
              width={56}
              className={styles.serviceItemImg}
              alt="service-geo"
              src={sGeoImg}
            />
            <TypographyUI.H4Bold className={styles.serviceItemTitle}>
              Заказчики со всей России
            </TypographyUI.H4Bold>
            <TypographyUI.Body1Regular
              className={styles.serviceItemDescription}
            >
              Если хотите работать с заказчиками из конкретного города, укажите
              адрес в профиле или воспользуйтесь фильтром при поиске новых
              заданий
            </TypographyUI.Body1Regular>
          </div>
          <div className={styles.serviceItem}>
            <img
              height={56}
              width={56}
              className={styles.serviceItemImg}
              alt="service-wallet"
              src={sWalletImg}
            />
            <TypographyUI.H4Bold className={styles.serviceItemTitle}>
              Оплата любым
              <br />
              удобным способом
            </TypographyUI.H4Bold>
            <TypographyUI.Body1Regular
              className={styles.serviceItemDescription}
            >
              Получите деньги за выполненную работу наличными, на карту любого
              банка или электронный кошелек, а мы сами отправим данные в «Мой
              налог»
            </TypographyUI.Body1Regular>
          </div>
          <div className={styles.serviceItem}>
            <img
              height={56}
              width={56}
              className={styles.serviceItemImg}
              alt="service-doc"
              src={sDocImg}
            />
            <TypographyUI.H4Bold className={styles.serviceItemTitle}>
              Автоматическое создание документов
            </TypographyUI.H4Bold>
            <TypographyUI.Body1Regular
              className={styles.serviceItemDescription}
            >
              Сам.Маркет составит необходимые бумаги: договор, доп. соглашения,
              акты, счета и чеки, а еще поможет в их обмене и подписании
            </TypographyUI.Body1Regular>
          </div>
          <div className={styles.serviceItem}>
            <img
              height={56}
              width={56}
              className={styles.serviceItemImg}
              alt="service-fns"
              src={sFnsImg}
            />
            <TypographyUI.H4Bold className={styles.serviceItemTitle}>
              Полная интеграция с приложением «Мой налог»
            </TypographyUI.H4Bold>
            <TypographyUI.Body1Regular
              className={styles.serviceItemDescription}
            >
              Платформа проверяет ваш налоговый статус и сообщает его заказчику.
              Вся информация о доходах автоматически направляется в «Мой налог»
            </TypographyUI.Body1Regular>
          </div>
        </div>

        <div className={styles.containerHowFindOrder}>
          <TypographyUI.H2
            swichPoint={LAPTOP_MOBILE_POINTER}
            className={styles.title}
          >
            Как быстро найти заказ
          </TypographyUI.H2>
          <div className={styles.orderSteps}>
            <img src={srcOrderStepsImg} alt="" />
          </div>
          <div className={styles.orderItems}>
            <div className={styles.orderItem}>
              <div className={styles.orderItemImg}>
                <StaticImage
                  src="../../images/selfworkers/order-step-1.png"
                  loading="eager"
                  placeholder="none"
                  layout="constrained"
                  objectFit="contain"
                  alt="order-step-1"
                />
              </div>
              <img
                className={styles.orderItemStepNum}
                src={srcOrderStep1Img}
                alt="order-num-1"
              />
              <div className={styles.orderItemText}>
                <TypographyUI.H4Bold className={styles.orderItemTitle}>
                  Заполните профиль и получайте отклики
                </TypographyUI.H4Bold>
                <TypographyUI.Body1Regular
                  className={styles.orderItemDescription}
                >
                  Опишите опыт работы, добавьте портфолио и получите первые
                  отклики от заказчиков
                </TypographyUI.Body1Regular>
              </div>
            </div>

            <div className={styles.orderItem}>
              <div className={styles.orderItemImg}>
                <StaticImage
                  src="../../images/selfworkers/order-step-2.png"
                  loading="eager"
                  placeholder="none"
                  layout="constrained"
                  objectFit="contain"
                  alt="order-step-2"
                />
              </div>
              <img
                className={styles.orderItemStepNum}
                src={srcOrderStep2Img}
                alt="order-num-2"
              />
              <div className={styles.orderItemText}>
                <TypographyUI.H4Bold className={styles.orderItemTitle}>
                  Ищите задания
                </TypographyUI.H4Bold>
                <TypographyUI.Body1Regular
                  className={styles.orderItemDescription}
                >
                  Воспользуйтесь фильтром для поиска актуальной работы и
                  включите уведомления о появлении новых задач
                </TypographyUI.Body1Regular>
              </div>
            </div>

            <div className={styles.orderItem}>
              <div className={styles.orderItemImg}>
                <StaticImage
                  src="../../images/selfworkers/order-step-3.png"
                  loading="eager"
                  placeholder="none"
                  layout="constrained"
                  objectFit="contain"
                  alt="order-step-3"
                />
              </div>
              <img
                className={styles.orderItemStepNum}
                src={srcOrderStep3Img}
                alt="order-num-3"
              />
              <div className={styles.orderItemText}>
                <TypographyUI.H4Bold className={styles.orderItemTitle}>
                  Начните сотрудничество
                </TypographyUI.H4Bold>
                <TypographyUI.Body1Regular
                  className={styles.orderItemDescription}
                >
                  Договоритесь о деталях, заключите договор и приступайте к
                  работе
                </TypographyUI.Body1Regular>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.containerAdditionalService}>
          <TypographyUI.H2
            swichPoint={LAPTOP_MOBILE_POINTER}
            className={styles.title}
          >
            Дополнительные возможности сервиса
          </TypographyUI.H2>

          <div className={styles.additionalServiceItems}>
            <div className={styles.additionalServiceItem}>
              <div className={styles.additionalServiceItemImg}>
                <StaticImage
                  src="../../images/selfworkers/additional-service-1.png"
                  loading="eager"
                  placeholder="none"
                  layout="constrained"
                  objectFit="contain"
                  alt="additional-service-1"
                />
              </div>
              <div className={styles.additionalServiceItemText}>
                <TypographyUI.H3 className={styles.additionalServiceItemTitle}>
                  Электронный кошелек со множеством функций
                </TypographyUI.H3>
                <TypographyUI.Body1Regular
                  className={styles.additionalServiceItemDescription}
                >
                  На наш кошелек можно не только принимать деньги, но и
                  создавать с его помощью уникальные ссылки для оплаты товаров и
                  услуг
                </TypographyUI.Body1Regular>
                <Link to="/wallet">
                  <ButtonUI.Secondary
                    className={styles.additionalServiceItemButton}
                    $w="240px"
                  >
                    Подробнее
                  </ButtonUI.Secondary>
                </Link>
              </div>
            </div>

            <div className={styles.additionalServiceItem}>
              <div className={styles.additionalServiceItemImg}>
                <StaticImage
                  src="../../images/selfworkers/additional-service-2.png"
                  loading="eager"
                  placeholder="none"
                  layout="constrained"
                  objectFit="contain"
                  alt="additional-service-2"
                />
              </div>
              <div className={styles.additionalServiceItemText}>
                <TypographyUI.H3 className={styles.additionalServiceItemTitle}>
                  Интернет-эквайринг
                </TypographyUI.H3>
                <TypographyUI.Body1Regular
                  className={styles.additionalServiceItemDescription}
                >
                  Настройте оплату товаров и автоматическую отправку чеков на
                  вашем сайте без обращения в банк
                </TypographyUI.Body1Regular>
                <Link to="/acquiring">
                  <ButtonUI.Secondary
                    className={styles.additionalServiceItemButton}
                    $w="240px"
                  >
                    Подробнее
                  </ButtonUI.Secondary>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.containerBanner}>
          <div className={styles.bannerText}>
            <TypographyUI.H2
              swichPoint={LAPTOP_MOBILE_POINTER}
              className={styles.bannerTitle}
            >
              Начните зарабатывать больше
            </TypographyUI.H2>
            <TypographyUI.Body1Regular className={styles.bannerDescription}>
              Установите Сам.Маркет и найдите свой первый заказ
            </TypographyUI.Body1Regular>
            <div className={styles.bannerDownload}>
              <a
                className={styles.bannerDownloadLink}
                rel="noreferrer"
                target="_blank"
                href="https://apps.apple.com/app/id1488581137"
              >
                <StaticImage
                  className={styles.bannerDownloadAppStore}
                  src="../../images/shared/app-store.png"
                  loading="eager"
                  placeholder="none"
                  layout="constrained"
                  objectFit="contain"
                  alt="app-store"
                />
              </a>
              <a
                className={styles.bannerDownloadLink}
                rel="noreferrer"
                target="_blank"
                href="https://play.google.com/store/apps/details?id=ru.selfwork.marketplace"
              >
                <StaticImage
                  className={styles.bannerDownloadGoogleStore}
                  src="../../images/shared/google-store.png"
                  loading="eager"
                  placeholder="none"
                  layout="constrained"
                  objectFit="contain"
                  alt="app-store"
                />
              </a>
            </div>
            <img
              className={styles.bannerArrow}
              src={bannerArrowImg}
              alt="banner-arrow"
            />
          </div>

          <div className={styles.bannerImg}>
            <StaticImage
              src="../../images/selfworkers/banner.png"
              loading="eager"
              placeholder="none"
              layout="constrained"
              objectFit="contain"
              alt="banner"
            />
          </div>
        </div>
      </Layout>
    </>
  );
};

export default SelfworkersPage;
