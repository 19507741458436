// extracted by mini-css-extract-plugin
export var additionalServiceItem = "style-module--additional-service-item--81b3a";
export var additionalServiceItemButton = "style-module--additional-service-item-button--25f69";
export var additionalServiceItemDescription = "style-module--additional-service-item-description--28c63";
export var additionalServiceItemImg = "style-module--additional-service-item-img--19781";
export var additionalServiceItemText = "style-module--additional-service-item-text--c4294";
export var additionalServiceItemTitle = "style-module--additional-service-item-title--40b8f";
export var additionalServiceItems = "style-module--additional-service-items--f6d2c";
export var background = "style-module--background--d792c";
export var backgroundImg = "style-module--background-img--a8411";
export var bannerArrow = "style-module--banner-arrow--ffdbc";
export var bannerDescription = "style-module--banner-description--7a47e";
export var bannerDownload = "style-module--banner-download--bf81f";
export var bannerDownloadAppStore = "style-module--banner-download-app-store--b7a4f";
export var bannerDownloadGoogleStore = "style-module--banner-download-google-store--1d7a4";
export var bannerDownloadLink = "style-module--banner-download-link--e041e";
export var bannerImg = "style-module--banner-img--d4e2f";
export var bannerText = "style-module--banner-text--e1347";
export var bannerTitle = "style-module--banner-title--d43da";
export var containerAdditionalService = "style-module--container-additional-service--7e6d8";
export var containerBanner = "style-module--container-banner--aee6e";
export var containerHowFindOrder = "style-module--container-how-find-order--d4237";
export var containerServiceFor = "style-module--container-service-for--02ea2";
export var containerServices = "style-module--container-services--7bded";
export var containerTop = "style-module--container-top--4eead";
export var description = "style-module--description--b7fee";
export var orderItem = "style-module--order-item--df329";
export var orderItemDescription = "style-module--order-item-description--677a0";
export var orderItemImg = "style-module--order-item-img--7c770";
export var orderItemStepNum = "style-module--order-item-step-num--a5fc4";
export var orderItemText = "style-module--order-item-text--46eb7";
export var orderItemTitle = "style-module--order-item-title--dc746";
export var orderItems = "style-module--order-items--271d6";
export var orderSteps = "style-module--order-steps--a1d0f";
export var serviceItem = "style-module--service-item--9815d";
export var serviceItemDescription = "style-module--service-item-description--d4a06";
export var serviceItemImg = "style-module--service-item-img--0aac7";
export var serviceItemTitle = "style-module--service-item-title--0041e";
export var text = "style-module--text--10088";
export var title = "style-module--title--2f0b3";
export var tryButton = "style-module--try-button--2e481";